<!-- 详情页布局，无页脚 -->
<template>
    <div class="detail-layout">
        <common-header />
        <div class="router-view">
            <div class="router-view-inner">
                <slot name="routerView" />
            </div>
            <div class="back-top">
                <back-top/>
            </div>
        </div>
        <!-- <common-footer /> -->
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="less" scoped>
.detail-layout {
    min-width: 1300px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    // 路由出口
    .router-view {
        width: 100%;
        flex: 1;
        background: #f5f5f5;
        box-sizing: border-box;
        .router-view-inner {
            margin: 0 auto;
            max-width: 1840px;
            box-sizing: border-box;
        }
        // 回到顶部
        .back-top{
            @apply fixed;
            bottom: 50px;
            right: 0;
        }
    }
}
</style>